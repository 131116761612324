













// external dependencies
import { Component, Prop, Vue } from 'vue-property-decorator';
// child components
import NavigationTabs from '@/components/NavigationTabs/NavigationTabs.vue';

@Component({ components: { NavigationTabs } })
export default class AssetDashboardWrap extends Vue {
    @Prop({ default: 'mosaics' }) parentRouteName: string;
}
